import { MdChevronRight } from 'react-icons/md'

import { Image } from 'src/components/atoms/Image'
import { InternalLink } from 'src/components/atoms/Link'
import { Paragraph } from 'src/components/atoms/Typography'

import colors from 'src/styles/theme/colors'

type ActionLinkCardProps = {
  title: string
  type: 'gift' | 'donate' | 'fundraiser'
  fullWidth: boolean
}

type ActionLinkCardConfig = {
  [key in ActionLinkCardProps['type']]: {
    icon: string
    ctaPath: string
  }
}

export const ActionLinkCard = ({
  title,
  type,
  fullWidth
}: ActionLinkCardProps) => {
  const config: ActionLinkCardConfig = {
    gift: { ctaPath: '/gift', icon: '/img/icons/gift-trees-dark.svg' },
    donate: { ctaPath: '/donate', icon: '/img/icons/fund-dark.svg' },
    fundraiser: {
      ctaPath: '/registries',
      icon: '/img/icons/crowdfund-dark.svg'
    }
  }
  return (
    <InternalLink href={config[type].ctaPath}>
      <div
        className={`rounded-[10px] group flex flex-row bg-white p-5 ${
          fullWidth ? 'lg:px-[30px] lg:py-5 lg:gap-5' : ''
        } items-center gap-[10px] hover:shadow-[0px_0px_10px_0px_rgba(0,_0,_0,_0.10)] transition-all duration-300 border border-solid border-superSilver`}
      >
        <div
          className={`relative w-10 h-10 ${fullWidth ? 'lg:w-20 lg:h-20' : ''}`}
        >
          <Image
            alt={type}
            className='object-contain'
            fill
            src={config[type].icon}
          />
        </div>
        <Paragraph
          className={`!m-0 text-mostlyGreen flex-1 text-[16px] font-bold ${
            fullWidth ? 'lg:font-bold lg:text-[20px]' : ''
          }`}
        >
          {title}
        </Paragraph>
        <MdChevronRight
          className='transition-all duration-300 group-hover:translate-x-2 '
          size='24px'
          color={colors.mostlyGreen}
        />
      </div>
    </InternalLink>
  )
}
