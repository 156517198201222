module.exports = {
  // Default colors
  background: '#fafafa',

  primary: '#f05b62',
  primaryLight: '#F37B81',
  primaryDark: '#CA3239',

  secondary: '#9ec392',
  secondaryLight: '#B1CFA7',
  secondaryDark: '#88ad7b',

  tertiary: '#85cfc9',
  tertiaryLight: '#9DD8D3',
  tertiaryDark: '#5D908C',

  // TODO: swap these out for better colors if you can find them (maybe in the old app?)
  success: '#398500',
  info: '#1859D6',
  error: '#D62518',
  warning: '#EB8900',
  warningLight: '#FFEBCE',
  // Primary colors
  aquaSpring: '#e7f1e5',
  aquaSpringLight: '#F1F7EF',
  como: '#436e54',
  cyan2: '#77c1b1',
  cyan: '#85cfc9',
  froly: '#f27b81',
  earth: '#85C9BE',
  mostlyGreen: '#48785d', // replaces zucchini
  darkGreen: '#3B684F',
  olivine: '#9ec392',
  olivineDark: '#88ad7b',
  tomThumb: '#445a3e',
  stripeBlue: '#E8E9F0',
  stripeError: '#fa755a',
  outline: '#4D90FE',

  // Secondary colors
  alizarin: '#e73941', // link-color // replaces flame-pea
  lightRed: '#f05b62', // button-color
  lightRed2: '#f0576a', // link-focus/hover
  lightRed3: '#e47478', // account actions link focus

  // Whites/Greys/Blacks
  abbey: '#595b5f',
  alabaster: '#f4f3ef',
  alto: '#dddddd',
  lightGrey: '#f9f9f9',
  anotherGrey1: '#bbbbbb',
  anotherGrey2: '#666666',
  anotherGrey3: '#7e8082',
  anotherGrey4: '#EFEFEF',
  avatarBorderGrey: '#979797',
  charcoal: '#444444',
  davyGrey: '#555555',
  doveGrey: '#616161',
  dropshadowBlack: 'rgba(0, 0, 0, 0.15)',
  dropshadowGrey: 'rgba(93,89,89,.2)',
  iconGrey: '#C0C0C0',
  iron: '#d7d7d8',
  ivoryBlack: '#231f20',
  linen: '#f9f5f0',
  osloGrey: '#97999b',
  silver: 'rgb(192,192,192)',
  shark: '#303338',
  snow2: '#fcf8f8',
  superSilver: '#eeeeee',
  white2: 'rgba(255, 255, 255, 0.5)',
  white: '#ffffff',

  // Other colors
  rose: '#b86894',
  yellow: '#ffcc5a',
  green2: '#85bf6e',
  blue: '#25bcca',
  fountainBlue: '#50b0b3',
  yellow2: '#e6b824'
}
